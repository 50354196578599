import {Box, Button, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectCount} from "../../counter/counterSlice";
import {selectDenuncia, start} from "../denunciaSlice";

export function Intro() {

  const count = useAppSelector(selectDenuncia);
  const dispatch = useAppDispatch();

  return (<>

      <Typography component="h1" variant="h5">
        Denunciacristalli.it
      </Typography>
      <Box component="div" sx={{mt: 1}}>

        <Typography component="body" variant="body2">
          Benvenuti nel nostro sito di inoltro documentazione assicurazione cristalli! Siamo lieti di offrire ai nostri
          clienti la possibilità di inviare la documentazione relativa alla loro assicurazione cristalli in modo
          semplice e veloce. Con il nostro servizio, gli utenti dell'officina Carglass di Foggia e Manfredonia possono
          inoltrare la loro documentazione senza doversi recare in filiale.
          <br/>
          Per utilizzare il nostro servizio, è necessario che l'utente abbia a disposizione il libretto di circolazione
          della propria auto e una foto del vetro danneggiato. Una volta che l'utente ha questi documenti, può
          semplicemente seguire i passaggi del nostro sito per inviare la documentazione. Il processo è rapido e facile
          da seguire.
          <br/>
          Il nostro servizio si rivolge solo alle officine Carglass di Foggia e Manfredonia, quindi gli utenti che si
          trovano in queste zone possono beneficiare di questa comodità. Siamo orgogliosi di offrire questo servizio
          ai nostri clienti e siamo sempre a disposizione per aiutarli in caso di domande o problemi.
          <br/>
          Grazie per aver scelto il nostro sito per inoltrare la vostra documentazione assicurazione cristalli.
          Siamo sicuri che troverete il processo semplice e veloce, e siamo sempre a disposizione per aiutarvi in
          caso di domande o problemi.
        </Typography>

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{mt: 3, mb: 2}}
          onClick={() => dispatch(start())}
        >
          Compila
        </Button>
      </Box>
    </>
  );
}
