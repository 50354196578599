import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SelettoreVetri from "./SelettoreVetri";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Autocomplete} from "@mui/material";
import {vetri} from "../../../data/vetri";

export default function SinistroStep() {

  const [data, setData] = React.useState<Date | null>(new Date());


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dati sinistro
      </Typography>
      <Grid container spacing={3}>


        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data sinistro"
              //name="anno"
              value={data}
              onChange={(newValue) => {
                setData(newValue);
              }}
              renderInput={(params) => <TextField
                fullWidth
                autoComplete="sinistro-data"
                variant="standard"
                {...params} helperText={null}/>}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="sinistro-luogo"
            name="sinistro-luogo"
            label="Luogo sinistro"
            fullWidth
            autoComplete="sinistro-luogo"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="sinistro-provincia"
            name="sinistro-provincia"
            label="Provincia"
            fullWidth
            autoComplete="sinistro-provincia"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="vetri"
            options={vetri}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Vetri interessati" placeholder="seleziona i vetri danneggiati"/>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Città"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="Provincia"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="cap"
            name="cap"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes"/>}
            label="Use this address for payment details"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
