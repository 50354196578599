import React, {useState} from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  DialogActions, DialogContentText, Dialog, DialogTitle, DialogContent, Stack, Autocomplete
} from '@mui/material';
import {Button, Modal, Backdrop, Fade} from '@mui/material';
import Typography from "@mui/material/Typography";
import {FormContainer} from "react-hook-form-mui";
import {compagnie} from "../../../data/compagnie";

type PolizzaStepProps = {};

const PolizzaStep: React.FC<PolizzaStepProps> = () => {
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (event.target.value === 'no') setOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack>
      <React.Fragment>
        <FormContainer>
          <Typography variant="h6" gutterBottom>
            I tuoi dati
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <FormControl>
                <FormLabel>Il veicolo è assicurato con una copertura cristalli?</FormLabel>
                <RadioGroup aria-label="si-no" name="si-no" value={value} onChange={handleChange}>
                  <FormControlLabel value="yes" control={<Radio/>} label="Sì"/>
                  <FormControlLabel value="no" control={<Radio/>} label="No"/>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl>
                <FormLabel>
                  Selezionare la compagnia assicurativa
                </FormLabel>
                <Autocomplete
                  freeSolo
                  id="polizza"
                  disableClearable
                  options={compagnie.map((option) => option.label)}
                  renderInput={(params) => (
                    <TextField
                      {...params}

                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

          </Grid>
        </FormContainer>
      </React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Servizio non disponibile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Il servizio è disponibile solo per veicoli dotati di polizza
            cristalli!<br/>
            In assenza della polizza è possibile contattare l'ufficio e concordare
            un appuntamento.

          </DialogContentText>
          {/*todo: inserire contatto telefonico?*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

    </Stack>
  );
}

export default PolizzaStep;
