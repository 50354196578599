import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

export function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link target={'_blank'} color="inherit" href="http://www.vipglass.it/">
                VIP GLASS SAS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            <br/>
            Sede legale in Manfredonia (Fg), Via Gargano 218-220. Sede operativa in Foggia Via Manfredonia 22. <br/>
            Partita IVA 03595780713 - Iscrizione Registro imprese di Foggia
        </Typography>

    );
}
