import axios from 'axios';
import {SERVER_BASE_URL} from "../../utils/utils";

export async function salvaDenuncia(denuncia: any) {

  const url = SERVER_BASE_URL + '/denuncia';

  console.log('salvaDenuncia: ', denuncia)

  return axios.post(url, denuncia)

}
