import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../../app/store';
import {salvaDenuncia} from "./denunciaAPI";
import {DenunciaModel} from "./models";


export enum DenunciaAvanzamento {
  Intro = 'intro',
  Polizza = 'polizza',

  Auto = 'auto',
  Cliente = 'cliente',
  Sinistro = 'sinistro',
  Riepilogo = 'riepilogo',


}

enum DenunciaStatus {
  idle = 'idle',

  saving = 'saving',
  failed = 'failed',


}

export interface DenunciaState {

  denuncia: DenunciaModel;


  avanzamento: DenunciaAvanzamento

  status: DenunciaStatus;
}

const initialState: DenunciaState = {

  denuncia: {
    auto: undefined,
    cliente: undefined,
    polizza: undefined,
    sinistro: undefined,
  },

  avanzamento: DenunciaAvanzamento.Intro,
  status: DenunciaStatus.idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'denuncia/salva',
  async (amount: number, thunkAPI: any) => {
    const denuncia = selectDenuncia(thunkAPI.getState());
    const response = await salvaDenuncia(denuncia);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const denunciaSlice = createSlice({
  name: 'denuncia',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    start: (state) => {
      state.avanzamento = DenunciaAvanzamento.Polizza
    },

    salvaPolizza: (state, action: PayloadAction<any>) => {
      state.denuncia.polizza = action.payload;
      state.avanzamento = DenunciaAvanzamento.Auto;
    },

    salvaAuto: (state, action: PayloadAction<any>) => {
      state.denuncia.auto = action.payload;
      state.avanzamento = DenunciaAvanzamento.Cliente;
    },

    salvaCliente: (state, action: PayloadAction<any>) => {
      state.denuncia.cliente = action.payload;
      state.avanzamento = DenunciaAvanzamento.Sinistro;
    },

    salvaSinistro: (state, action) => {
      state.denuncia.sinistro = action.payload;
      state.avanzamento = DenunciaAvanzamento.Riepilogo;
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = DenunciaStatus.saving;
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = DenunciaStatus.saving;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = DenunciaStatus.failed;
      });
  },
});

export const {start, salvaAuto, salvaSinistro, salvaPolizza, salvaCliente} = denunciaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDenuncia = (state: RootState) => state.denuncia;


export default denunciaSlice.reducer;
