import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import {FormContainer} from "react-hook-form-mui";


enum Contraente {
  PERSONA_FISICA = "persona",
  AZIENDA = "azienda"
}

export default function UtenteStep() {

  const [contraente, setContraente] = useState<Contraente>(Contraente.PERSONA_FISICA);

  return (
    <React.Fragment>
      <FormContainer>
        <Typography variant="h6" gutterBottom>
          I tuoi dati
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Nome"
              fullWidth
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Cognome"
              fullWidth
              variant="standard"
              autoComplete="family-name"

            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Tipologia contraente</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contraente}
                label="Tipologia contraente"
                fullWidth
                variant="standard"
                autoComplete="tipologia-contraente"
                onChange={(e) => setContraente(e.target.value as Contraente)}
              >
                <MenuItem value={Contraente.PERSONA_FISICA}>Persona fisica</MenuItem>
                <MenuItem value={Contraente.AZIENDA}>Azienda</MenuItem>

              </Select>
            </FormControl>

          </Grid>
          {contraente === Contraente.AZIENDA &&
              <Grid item xs={12}>
                  <TextField
                      required
                      id="ragione-sociale"
                      name="ragione-sociale"
                      label="Ragione sociale"
                      fullWidth
                      autoComplete="ragione-sociale"
                      variant="standard"
                  />
              </Grid>
          }
          {contraente === Contraente.AZIENDA &&
              <Grid item xs={12}>
                  <TextField
                      required
                      id="partita-iva"
                      name="partita-iva"
                      label="Partita iva"
                      fullWidth
                      autoComplete="partita-iva"
                      variant="standard"
                  />
              </Grid>
          }
          {contraente !== Contraente.AZIENDA &&
              <Grid item xs={12}>
                  <TextField
                      required
                      id="codice-fiscale"
                      name="codice-fiscale"
                      label="Codice fiscale"
                      fullWidth
                      autoComplete="codice-fiscale"
                      variant="standard"
                  />
              </Grid>
          }
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Indirizzo"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="Città"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
            />
          </Grid>
          <Grid item xs={8} sm={4} lg={2}>
            <TextField
              id="state"
              name="state"
              label="Provincia"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <TextField
              required
              id="cap"
              name="cap"
              label="Cap"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <TextField
              required
              id="country"
              name="country"
              label="Country"
              fullWidth
              autoComplete="shipping country"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              required
              id="numero-telefono"
              name="numero-telefono"
              label="Telefono"
              fullWidth
              autoComplete="telefono"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              variant="standard"
            />
          </Grid>
          {contraente === Contraente.AZIENDA &&
              <Grid item xs={6} sm={4}>
                  <TextField
                      required
                      id="codice-destinatario"
                      name="codice-destinatario"
                      label="Codice destinatario"
                      fullWidth
                      autoComplete="codice-destinatario"
                      variant="standard"
                  />
              </Grid>
          }
          {contraente === Contraente.AZIENDA &&
              <Grid item xs={6}>
                  <TextField
                      required
                      id="pec"
                      type="email"
                      name="pec"
                      label="PEC"
                      fullWidth
                      autoComplete="pec"
                      variant="standard"
                  />
              </Grid>
          }
        </Grid>
      </FormContainer>
    </React.Fragment>
  );
}
