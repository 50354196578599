export const vetri = [
  'Parabrezza',
  'Fisso carrozzeria sinistro',
  'Fisso carrozzeria destro',
  'Fisso porta anteriore sinistro',
  'Fisso porta anteriore destro',
  'Scendente anteriore sinistro',
  'Scendente anteriore destro',
  'Scendente posteriore sinistro',
  'Scendente posteriore destro',
  'Fisso porta posteriore sinistro',
  'Fisso porta posteriore destro',
  'Fisso carrozzeria posteriore sinistro',
  'Fisso carrozzeria posteriore destro',
  'Lunotto',
  'Lunotto destro',
  'Lunotto sinistro'

];
