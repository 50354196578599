import React from 'react';
import {Box, Container, createTheme, CssBaseline, Grid, makeStyles, Paper, Stack, Typography} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {DenunciaAvanzamento, selectDenuncia} from "../features/denuncia/denunciaSlice";
import {Intro} from "../features/denuncia/Components/Intro";

import Denuncia from "../features/denuncia/Components/Denuncia";
import {Copyright} from "../components/Copyright";


const Layout: React.FC = () => {

  const theme = createTheme();


  const heroUrl = process.env.PUBLIC_URL + '/images/hero.jpeg'
  const denuncia = useAppSelector(selectDenuncia);
  const dispatch = useAppDispatch();

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{height: '100vh'}}>
        <CssBaseline/>
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: 'url(' + heroUrl + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        />

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {denuncia.avanzamento === DenunciaAvanzamento.Intro ? <Intro/> : <Denuncia></Denuncia>}

            </Box>
            <footer>
              <Copyright sx={{my: 4}}/>
            </footer>
          </Stack>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Layout;
