import * as React from 'react';

import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PolizzaStep from "./PolizzaStep";
import VeicoloStep from "./VeicoloStep";
import UtenteStep from "./UtenteStep";
import SinistroStep from "./SinistroStep";
import Review from "./Review";
import {flushSync} from 'react-dom';
import {useRef} from "react";


const steps = [
  {
    label: 'Polizza',
    component: <PolizzaStep/>
  },
  {
    label: 'Veicolo',
    component: <VeicoloStep/>
  },
  {
    label: 'Utente',
    component: <UtenteStep/>
  },
  {
    label: 'Sinistro',
    component: <SinistroStep/>
  },
  {
    label: 'Riepilogo',
    component: <Review/>
  }
];

enum TipoUtente {
  Privato, PartitaIVA
}


export default function Denuncia() {
  const [activeStep, setActiveStep] = React.useState(0);

  const [tipoUtente, setTipoUtente] = React.useState<TipoUtente>(TipoUtente.Privato);

  const stepperRef = useRef<HTMLDivElement | null>(null);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    scrollToStepper()
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToStepper()
  };

  // const component = ({ChildClass}) => {
  //     return <ChildClass />
  // }

  const scrollToStepper = () => {
    flushSync(() => {
      stepperRef?.current?.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    })
  };

  return (

    <>

      <Typography component="h1" variant="h4" align="center">
        Compila denuncia
      </Typography>
      <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>

        <Stepper
          ref={stepperRef}
          activeStep={activeStep} sx={{pt: 3, pb: 5}} orientation={"vertical"}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <Review></Review>
          ) : (
            <React.Fragment>
              {steps[activeStep].component}
              <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                    Indietro
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{mt: 3, ml: 1}}
                >
                  {activeStep === steps.length - 1 ? 'Invia denuncia' : 'Avanti'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </>
  );
}
