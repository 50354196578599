import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ImagesUpload} from "../../../components/ImagesUpload";
import FormControl from '@mui/material/FormControl';
import {Autocomplete, FormLabel, Radio, RadioGroup} from "@mui/material";
import {ChangeEvent} from "react";

enum PossessoVeicolo {
  proprio = 'proprietà',
  nolo = 'noleggio'
}

/**
 * MArca
 * Modello
 * anno
 * targa
 * parte frontale del libretto di circolazione
 * @constructor
 */
export default function VeicoloStep() {

  const [anno, setAnno] = React.useState<Date | null>(new Date());
  const [possesso, setPossesso] = React.useState('possesso');


  const handleChangePossesso = (event: ChangeEvent<HTMLInputElement>) => {
    setPossesso(event.target.value);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        I dati della tua auto
      </Typography>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Possesso del veicolo</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={possesso}
              onChange={handleChangePossesso}

            >
              <FormControlLabel value={PossessoVeicolo.proprio} control={<Radio/>} label="Veicolo di proprietà"/>
              <FormControlLabel value={PossessoVeicolo.nolo} control={<Radio/>} label="Veicolo a noleggio"/>

            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="targa"
            name="targa"
            label="Targa"
            fullWidth
            autoComplete="targa"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="marca"
            name="marca"
            label="Marca"
            fullWidth
            autoComplete="car-model"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="modello"
            name="modello"
            label="Modello"
            fullWidth
            autoComplete="car-model"
            variant="standard"
          />
        </Grid>
        <Grid item xs={4} sm={2}>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              label="Anno immatricolazione"
              //name="anno"
              value={anno}
              onChange={(newValue) => {
                setAnno(newValue);
              }}
              renderInput={(params) => <TextField
                fullWidth
                autoComplete="car-year"
                variant="standard"
                {...params} helperText={null}/>}
            />
          </LocalizationProvider>

        </Grid>
        <Grid item xs={4} sm={2}>
          <TextField
            required
            id="km"
            name="km"
            label="Kilometri"
            fullWidth
            autoComplete="km-veicolo"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <h3>Libretto di circolazione</h3>
          <p>Si prega di caricare la parte frontale del libretto di circolazione</p>
          <ImagesUpload></ImagesUpload>

        </Grid>
        {possesso === PossessoVeicolo.nolo &&
            <Grid item xs={12}>
                <h3>Foto del danno</h3>
                <p>Si prega di caricare due foto del danno: una panoramica e una di dettaglio</p>
                <ImagesUpload></ImagesUpload>

            </Grid>
        }
      </Grid>
    </React.Fragment>
  );
}
