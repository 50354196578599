import React from "react";
import ImageUploading, {ImageListType} from "react-images-uploading";
import {Button} from "@mui/material";
import {PhotoCamera} from "@mui/icons-material";

export function ImagesUpload() {
  const [images, setImages] = React.useState([]);
  //todo, should be a prop
  const maxNumber = 2;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
      >
        {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps
          }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button variant="outlined" component="label" startIcon={<PhotoCamera/>}
                    style={isDragging ? {color: "red"} : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
            >
              Carica
            </Button>
            &nbsp;
            {images.length > 0 &&
                <Button onClick={onImageRemoveAll}>Rimuovi tutti</Button>
            }
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.dataURL} alt="" width="100"/>
                <div className="image-item__btn-wrapper">
                  <Button onClick={() => onImageUpdate(index)}>Cambia</Button>
                  <Button onClick={() => onImageRemove(index)}>Elimina</Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
